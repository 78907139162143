<template>
  <div class="order-table-box">
    <el-radio-group v-model="selectedItem" @change="getCheckValue">
      <!-- 表头 -->
      <div class="table-header">
        <span>品名</span>
        <span>材质</span>
        <span>规格</span>
        <span>钢厂</span>
        <span>数量/单位</span>
        <span>仓库</span>
        <span>订单金额</span>
        <span>状态</span>
        <span class="operation-tltle">操作</span>
      </div>
      <!-- 表体 -->
      <div class="table-body">
        <!-- 单条的数据 被选中的颜色是active -->
        <div
          v-for="(val, index) in tableData"
          :key="index"
          :class="['list', selectedItem === val.id ? 'active' : '']"
        >
          <!-- 上部分 -->
          <div class="top-box">
            <div class="left">
              <!-- 选择框 -->
              <!-- <el-radio :label="val.id" /> -->
              <!-- 标记 -->
              <span v-if="val.orderSource === '02'" class="sign melt">集</span>
              <span
                v-if="val.financingStatus === '01'"
                class="sign "
              >融</span>
              <!-- 订单编号 -->
              <span class="attribute">订单编号：{{ val.orderNo }}</span>
              <!-- 买家 -->
              <span
                class="attribute"
              >买家：{{ val.buyerCmpName }}</span>
              <span
                class="attribute"
              >商家：{{ val.cmpName }}</span>
              <!-- 下单时间 -->
              <span class="attribute">下单时间：{{ val.createTime }}</span>
            </div>
            <!-- 右边的数据 -->
            <div class="right">
              <!-- 状态跟icon -->
              <div class="icon-box">
                <img
                  v-if="val.transfer === 1"
                  class="icon"
                  src="@/assets/icon/icon-transfer.png"
                  alt="icon"
                >
                <img
                  v-else
                  class="icon"
                  src="@/assets/icon/icon-pick.png"
                  alt="icon"
                >
                <span>{{
                  val.buyerPickUp === 1
                    ? "买家自提"
                    : val.merchantDistribution === 1
                      ? "商家配送"
                      : "过户"
                }}</span>
              </div>
              <div class="icon-box">
                <img
                  class="icon"
                  src="@/assets/icon/icon-total-payment.png"
                  alt="icon"
                >
                <span>{{
                  val.payStyle === "00" ? "全款支付" : "保证金支付"
                }}</span>
              </div>
              <div class="icon-box">
                <img
                  v-if="val.orderSource === '02'"
                  class="icon"
                  src="@/assets/icon/icon-order.png"
                  alt="icon"
                >
                <img
                  v-else
                  class="icon"
                  src="@/assets/icon/icon-place-order.png"
                  alt="icon"
                >
                <span>
                  {{ getDictionaryName('ORDER_SOURCE', val.orderSource) }}
                </span>
              </div>
              <!-- 多余一条的时候才出现 -->
              <div v-if="val.productLists?.length > 1" class="total-box">
                共有{{ val.productLists.length || 0 }}条数据
              </div>
            </div>
          </div>
          <!-- 下部分啊 -->
          <div class="bottom-box">
            <!-- 商品列表 -->
            <div class="product-list-box">
              <div v-for="item in val.productLists" :key="item.id" class="item">
                <span
                  class="elp-1"
                >{{ item.goodsTopCategoryName }}/{{ item.goodsOneCategoryName
                }}/{{ item.goodsTwoCategoryName }}</span>
                <span class="elp-1">{{ item.goodsMaterialName }}</span>
                <span class="elp-1">{{ item.goodsSpecName }}</span>
                <span class="elp-1">{{ item.manufactor }}</span>
                <span class="elp-1">{{ item.goodsWeight }}</span>
              </div>
            </div>
            <!-- 仓库名 -->
            <p class="warehouse-name elp-1">
              {{ val.warehouseName }}
            </p>
            <!-- 金额 -->
            <p class="money elp-1">
              ￥{{ val.orderAmount || 0 }}
            </p>
            <!-- 状态 -->
            <div class="state-box">
              <div>
                <!-- 另外状态的类名是 state-otherwise -->
                <span v-if="['13','15','16'].includes(val.state)" class="state">
                  {{
                    (orderStausDictionary || [{ dictId: '', dictName: ''}])
                      .find((item) => item.dictId === val.state).dictName
                  }}
                </span>
                <span v-else class="state state-otherwise">
                  {{
                    (orderStausDictionary || [{ dictId: '', dictName: ''}])
                      .find((item) => item.dictId === val.state).dictName
                  }}
                </span>
                <!-- <span class="button" @click="viewDetails(val.id)">查看详情</span> -->
              </div>
            </div>
            <!-- 操作按钮 -->
            <div class="operation-box">
              <span v-for="(nape,num) in operationButtonArr[index]" :key="num" @click="handleEvent(nape, val, index)">{{ nape.label }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 没有数据时候的显示 -->
      <NoData v-if="tableData.length === 0" class="nodataBox" :type="3" />
    </el-radio-group>
  </div>
</template>

<script>
import NoData from '@/components/NoData.vue'
import { getDictionaryName } from '@/utils/util'
export default {
  components: { NoData },
  props: {
    tableColumn: {
      type: Array,
      default: () => {
        return []
      }
    },
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    currentView: {
      type: Object,
      default: () => {
        return {}
      }
    },
    operationButton: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      selectedItem: null,
      operationButtonArr: [],
      orderStausDictionary: []
    }
  },
  computed: {
    orderSourceOptions() {
      return this.$store.getters.getDictionaryItem('ORDER_SOURCE')
    }
  },
  watch: {
    tableData: {
      handler(newVal) {
        const buttonData = []
        if (newVal.length > 0) {
          // 判断勾选的有没有在所存在的数据中   存在则不进行勾选  不存在则需要重选
          let flag = false
          newVal.forEach((row, index) => {
            if (row.id === this.selectedItem) flag = true
            buttonData[index] = []
            this.operationButton.forEach((item) => {
              if (
              // 是否取反
                (item.negation && row[item.val] !== item.num) ||
              // 多个判断条件  要同时满足才能出现
              (item.child && this.handleButtonDisplay(item.child, row)) ||
              // 多个判断条件   有一个满足就出现
              (item.childOne && this.handleButtonDisplayOne(item.childOne, row)) ||
              (item.val && row[item.val] === item.num) ||
              // 其他情况
              (!item.negation &&
                !item.child &&
                !item.childOne &&
                !item.num &&
                !item.val)
              ) {
                buttonData[index].push({ ...item })
              }
            })
          })
          if (!flag) {
            this.selectedItem = null
            this.$emit('checkedValue', {})
          }
        }
        this.operationButtonArr = [...buttonData]
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    const orderStaus = (this.$store.getters.getDictionaryItem('order_status') || [{ dictId: '', dictName: '' }])
    this.orderStausDictionary = orderStaus.map((item) => {
      if (item.dictId === '13' || item.dictId === '15' || item.dictId === '16') {
        item.dictName = '已完成'
      }
      return item
    })
  },
  methods: {
    getDictionaryName,
    viewDetails(id) {
      this.$router.push({
        path: this.currentView.orderDetailsPath,
        query: { id }
      })
    },
    // 判断多个条件同时满足
    handleButtonDisplay(arr, row) {
      let falg = false
      falg = arr.every((item) => {
        if (!item.num) return row[item.val] // 如果没有num的话就表示这个是有数据就可了
        return row[item.val] === item.num // 如果有num就要比较数据是否相等
      })
      return falg
    },
    // 判断有一个满足就出现按钮
    handleButtonDisplayOne(arr, row) {
      let falg = false
      falg = arr.some((item) => {
        if (!item.num) return row[item.val] // 如果没有num的话就表示这个是有数据就可了
        return row[item.val] === item.num // 如果有num就要比较数据是否相等
      })
      return falg
    },
    // 点击按钮事件
    handleEvent(item, row, index) {
      item['handleEvent'](row, item.withParameters, index)
    },
    // 当改变值的时候
    getCheckValue(value) {
      const obj = this.tableData.find(item => value === item.id)
      this.$emit('checkedValue', obj)
    }
  }
}
</script>

  <style lang="scss" scoped>
  .order-table-box {
    width: 100%;
    border: 1px solid #ededed;
    .table-body {
      margin: 28px 16px 0;
      .list {
        font-size: 14px;
        padding: 0 16px 24px;
        background-color: #fbfbfb;
        border-radius: 4px;
        margin-bottom: 20px;
        .bottom-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 0 0 20px;
          .operation-box {
            flex: 0 0 82px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            span {
              color: #0172eb;
              margin-right: 16px;
              cursor: pointer;
            }
            span:nth-of-type(3n){
              margin-right: 0;
            }
          }
          .state-box {
            // display: flex;
            // justify-content: center;
            // flex-direction: column;
            flex: 1;
            >div{
              display: inline-flex;
              align-items: center;
              flex-direction: column;
            }
            .state {
              display: inline-block;
              padding: 0 8px;
              height: 28px;
              line-height: 28px;
              text-align: center;
              border-radius: 2px;
              opacity: 1;
              background: rgb(241, 248, 244);
              color: #09af42;
            }
            .state-otherwise {
              background: rgb(254, 244, 238);
              color: #ff8413;
            }
            .button {
              display: block;
              margin-top: 4px;
              color: #0172eb;
              cursor: pointer;
            }
          }
          .money,
          .warehouse-name {
            flex: 1;
            color: #666666;
            font-weight: 400;
          }
          .product-list-box {
            flex: 5;
            overflow-y: hidden;
            max-height: 98px;
            .item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #666;
              margin-bottom: 20px;
              span {
                flex: 1;
                padding-right: 6px;
              }
              span:last-of-type {
                padding-right: 0;
              }
            }
            .item:last-of-type {
              margin-bottom: 0;
            }
          }
        }
        .top-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 56px;
          border-bottom: 1px solid #e7e7e7;
          box-sizing: border-box;
          .right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: #999999;
            font-size: 13px;
            .icon-box {
              display: flex;
              align-items: center;
              margin-right: 8px;
              img {
                height: 24px;
                width: 24px;
                margin-right: 4px;
              }
            }
            .total-box {
              margin-left: 16px;
            }
          }
          .left {
            display: flex;
            align-items: center;
            .attribute {
              color: #000;
              font-weight: 400;
              margin-right: 16px;
            }
            .sign {
              display: inline-block;
              text-align: center;
              line-height: 24px;
              width: 24px;
              height: 24px;
              border-radius: 2px;
              opacity: 1;
              background: #FFF3E7;
              margin-right: 8px;
              color: #FF8413;
              font-size: 16px;
              font-weight: 500;
            }
            .melt {
              background: #FEEDE9;
              color: #F44C27;
            }
            :deep(.el-radio__label) {
              display: none;
            }
            .el-radio {
              margin-right: 16px;
            }
            :deep(.el-radio__input.is-checked .el-radio__inner) {
              border: #0172eb;
              background-color: #0172eb;
            }
          }
        }
      }
      .list:hover {
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
      }
      .active {
        background-color: rgb(245, 248, 254);
      }
    }
    :deep(.el-radio-group) {
      display: block;
    }
    .table-header {
      display: flex;
      align-items: center;
      height: 48px;
      background-color: rgb(249, 249, 249);
      border-bottom: 1px solid #ededed;
      padding: 0 32px 0 50px;
      span {
        flex: 1;
        color: #333333;
        font-size: 14px;
        font-weight: 500;
      }
      .operation-tltle {
        flex: 0 0 82px;
      }
    }
    .nodataBox{
      padding: 48px 0;
    }
  }
  </style>

