<template>
  <!-- 订单管理 -->
  <div class="orderManagementPageBox page-info-content">
    <Tabs :active-name="formInline.state" :tabs-list="tabsList" @getTabName="getTabChange" />
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    >
      <el-form-item label="商品类别" label-width="80px">
        <el-cascader
          ref="goodsCategoryRef"
          v-model="formInline.goodsCategoryArr"
          placeholder="请选择"
          :options="categoryOption"
          :props="categoryCascadeProps"
          clearable
          @change="categoryChange"
        />
      </el-form-item>
      <el-form-item label="材质" label-width="80px">
        <el-select
          v-model="formInline.goodsMaterialName"
          size="small"
          placeholder="选择完商品类别才会出现材质"
        >
          <el-option label="全部" value />
          <el-option
            v-for="(item, index) in goodsMaterialOption"
            :key="index"
            :label="item.materials"
            :value="item.materials"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="配送方式" label-width="80px">
        <el-select
          v-model="formInline.tradeGoodsDeliveryInfoId"
          size="small"
          placeholder="选择配送方式"
          @change="getDeliveryMethod"
        >
          <el-option label="全部" value />
          <el-option
            v-for="item in deliveryMethodOption"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="下单日期" label-width="80px">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="getDate"
        />
      </el-form-item>
      <el-form-item v-if="$route.path !== '/supplyChain/aggregatePurchaseOrder'" label="订单来源" label-width="80px">
        <el-select
          v-model="formInline.orderSource"
          size="small"
          placeholder="选择订单来源"
        >
          <el-option label="全部" value />
          <el-option
            v-for="item in $store.getters.getDictionaryItem('ORDER_SOURCE')"
            :key="item.dictId"
            :label="item.dictName"
            :value="item.dictId"
          />
        </el-select>
      </el-form-item>
    </FormSearch>
    <CardTable
      :table-column="itemData"
      :table-data="listData"
      :operation-button="operationButton"
    >
      <template #warehouseName="{ row }">
        <span>{{ row.warehouseName }}</span>
      </template>
      <template #orderAmount="{ row }">
        <span>{{ row.orderAmount }}</span>
      </template>
      <template #state="{ row }">
        <span>
          {{
            (orderStausDictionary || [{ dictId: '', dictName: ''}])
              .find((val) => val.dictId === row.state).dictName
          }}
        </span>
      </template>
      <template #buyerPickUp="{ row }">
        <span>{{
          row.buyerPickUp === 1
            ? "买家自提"
            : row.merchantDistribution === 1
              ? "商家配送"
              : "过户"
        }}</span>
      </template>
      <template #payStyle="{ row }">
        <span>{{ row.payStyle === "00" ? "全款支付" : "保证金支付" }}</span>
      </template>
      <template #orderSource="{ row }">
        <span>{{ getDictionaryName('ORDER_SOURCE', row.orderSource) }}</span>
      </template>
    </CardTable>
    <!-- 没有数据时候的显示 -->
    <NoData v-if="listData.length === 0" class="nodataBox" :type="3" />
    <!-- 分页 -->
    <Pagination
      :params="formInline"
      :total="total"
      :in-article="listData.length"
      :get-data-list="getdata"
    />
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import CardTable from './components/CardTable'
import NoData from '@/components/NoData.vue'
import Pagination from '@/components/Pagination2'
import Tabs from '@/components/Tabs.vue'
import { getDictionaryName } from '@/utils/util'
import { goodsGetGoodsCategoryList, goodsGetGoodsMaterialList, orderPagePlatOrder } from '@/api/shoppingMall'
export default {
  components: { FormSearch, CardTable, NoData, Pagination, Tabs },
  data() {
    return {
      formInline: {
        pageSize: 10,
        pageNum: 1,
        state: ''
      },
      dateRange: [],
      orderStausDictionary: [],
      tabsList: [
        { label: '全部订单', name: '' },
        { label: '待确认', name: '00,17' },
        { label: '待支付', name: '01,06' },
        // { label: '待签署', name: '03,04,05' },
        { label: '待入库', name: '19' },
        { label: '待发货', name: '10' },
        { label: '待提货', name: '08' },
        { label: '待过户', name: '09' },
        { label: '待出库', name: '11' },
        { label: '待收货', name: '12' },
        { label: '已完成', name: '13,15,16' },
        { label: '已取消', name: '14' }
      ],
      formItemArr: [
        { type: 'input', label: '订单编号', value: 'orderNo', width: '80px' },
        { type: 'input', label: '商家名称', value: 'cmpName', width: '80px' },
        { type: 'input', label: '买家名称', value: 'buyerCmpName', width: '80px' },
        { type: 'select', label: '合同签署', value: 'contractStatus', pLabel: 'dictName', pValue: 'dictId', child: [{ dictId: '02', dictName: '待买家签署' }, { dictId: '03', dictName: '待卖家签署' }, { dictId: '04', dictName: '已完成签署' }], width: '80px' }
      ],
      itemData: [
        { label: '品名', prop: 'goodsTwoCategoryName', width: 180 },
        { label: '材质', prop: 'goodsMaterialName', width: 180 },
        { label: '规格', prop: 'goodsSpecName', width: 150 },
        { label: '钢厂/厂家', prop: 'manufactor', width: 180 },
        { label: '数量/单位', prop: 'goodsWeight', width: 150, unit: '吨' },
        { label: '仓库', prop: 'warehouseName', rowspan: true, width: 180 },
        {
          label: '订单金额（元）',
          prop: 'orderAmount',
          rowspan: true,
          // type: "money",
          width: 180
        },
        {
          label: '状态',
          prop: 'state',
          rowspan: true,
          width: 180
        },
        { label: '', prop: 'financingStatus', type: 'isInfo' },
        { label: '', prop: 'orderSource', type: 'isInfo' },
        { label: '下单时间', prop: 'createTime', type: 'isInfo' },
        { label: '订单编号', prop: 'orderNo', type: 'isInfo' },
        { label: '商家', prop: 'cmpName', type: 'isInfo' },
        { label: '买家', prop: 'buyerCmpName', type: 'isInfo' },
        { label: '', prop: 'buyerPickUp', type: 'isFooter' },
        { label: '', prop: 'payStyle', type: 'isFooter' },
        { label: '', prop: 'orderSource', type: 'isFooter' }
      ],
      listData: [],
      total: 0,
      operationButton: [
        {
          bType: 'primary',
          label: '订单详情',
          handleEvent: this.orderDetails
        }
      ],
      // 划级联渲染规则
      categoryCascadeProps: {
        value: 'code',
        label: 'goodsName'
      },
      // 商品名称
      categoryOption: [],
      // 材质
      goodsMaterialOption: [],
      deliveryMethodOption: [{ id: 0, name: '商家配送' }, { id: 1, name: '买家自提' }, { id: 2, name: '过户' }]
    }
  },
  created() {
    if (this.$route.path === '/supplyChain/aggregatePurchaseOrder') {
      this.formInline.orderSource = '02'
    }
    this.getCategoryList()
    this.getdata()
    const orderStaus = (this.$store.getters.getDictionaryItem('order_status') || [{ dictId: '', dictName: '' }])
    this.orderStausDictionary = orderStaus.map((item) => {
      if (item.dictId === '13' || item.dictId === '15' || item.dictId === '16') {
        item.dictName = '已完成'
      }
      return item
    })
  },
  methods: {
    getDictionaryName,
    // 获取分页数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1,
          state: '',
          orderSource: this.formInline.orderSource,
          goodsCategoryArr: []
        }
        this.dateRange = []
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      orderPagePlatOrder(this.formInline, res => {
        this.listData = [...res.data.records]
        this.total = res.data.total
      })
    },
    getTabChange(val) {
      if (!val || val === '0') { this.formInline.state = '' } else { this.formInline.state = val }
      this.getdata(true)
    },
    // 品类 级联选择器数据
    getCategoryList() {
      goodsGetGoodsCategoryList((res) => {
        this.categoryOption = res.data
        this.changeCategoryList(this.categoryOption)
      })
    },
    // 递归删除空子级
    changeCategoryList(data) {
      data.map((item) => {
        this.changeCategoryList(item.children)
        if (item.children.length === 0) {
          delete item.children
        }
      })
    },
    // 品名
    categoryChange(val) {
      if (!val || val.length === 0) {
        this.formInline.goodsTwoCategoryName = ''
        this.formInline.goodsMaterialName = ''
        this.goodsMaterialOption = []
        return
      }
      const goodsArr = this.$refs.goodsCategoryRef.getCheckedNodes()[0].pathLabels
      this.formInline.goodsTwoCategoryName = goodsArr[2]
      this.associativeSearch(val[2])
    },
    // 材质跟型号的搜索
    associativeSearch(data) {
      goodsGetGoodsMaterialList(data, (res) => {
        this.goodsMaterialOption = [...res.data]
      })
    },
    // 配送方式
    getDeliveryMethod(val) {
      switch (val) {
        case 0:
          this.formInline.merchantDistribution = 1
          this.formInline.buyerPickUp = 0
          this.formInline.transfer = 0
          break
        case 1:
          this.formInline.merchantDistribution = 0
          this.formInline.buyerPickUp = 1
          this.formInline.transfer = 0
          break
        case 2:
          this.formInline.merchantDistribution = 0
          this.formInline.buyerPickUp = 0
          this.formInline.transfer = 1
          break
        default:
          this.formInline.merchantDistribution = 0
          this.formInline.buyerPickUp = 0
          this.formInline.transfer = 0
          break
      }
    },
    // 获取日期
    getDate(value) {
      if (!value) {
        return (
          (this.formInline.startDate = ''), (this.formInline.endDate = '')
        )
      }
      this.formInline.startDate = value[0]
      this.formInline.endDate = value[1]
    },
    // 订单详情
    orderDetails({ id }) {
      this.$router.push({ path: '/shoppingMall/orderDetails', query: { id }})
    }
  }
}
</script>
